<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import SignaturePad from "signature_pad";
import Swal from "sweetalert2";
import FormTtd from "./form-ttd.vue";
import axios from "axios";
import $ from "jquery";
import draggable from "vuedraggable";
import Cookies from "js-cookie";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Edit Agenda Rapat",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    FormTtd,
    draggable,
  },
  data() {
    return {
      title: "Edit Agenda Rapat Direksi",
      items: [
        {
          text: "Administrasi Rapat",
          href: "/",
        },
        {
          text: "Absensi Rapat Direksi",
          href: "/administrasi/rapat-direksi",
        },
        {
          text: "Edit",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page
      showModal: false,
      showModalTtd: false,
      showFormTtd: false,
      // canvas draw tanda tangan
      initialImage: [
        {
          type: "dash",
          from: {
            x: 262,
            y: 154,
          },
          coordinates: [],
          color: "#000000",
          width: 5,
          fill: false,
        },
      ],
      x: 0,
      y: 0,
      image: "",
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 1,
      color: "#000000",
      strokeType: "dash",
      lineCap: "square",
      lineJoin: "miter",
      backgroundColor: "#FFFFFF",
      backgroundImage: null,
      watermark: null,
      additionalImages: [],

      loadingTable: true,
      dataTable: [],
      pagingTable: [],
      showDataToTable: "",
      showDataFromTable: "",
      totalDataTable: "",
      currentTablePage: "",
      searchDataTable: "",

      status: "ENABLE",
      master_divisi: [],
      divisi_item: [],
      showLokasiLainnya: false,
      ar_lokasi: "",
      ar_tanggal: "",
      ar_jumlah: "",
      ar_lokasi_lainnya: "",
      absensi_rapat_agenda: [],
      kehadiran_direksi: [],
      kehadiran_sevp: [],
      ttd_kehadiran_direksi: [],
      ttd_kehadiran_pemateri: [],
      ttd_kehadiran_pendamping: [],
      ttd_kehadiran_sevp: [],
      waktu_rapat: "",
      jumlah_hadir_direksi: "",
      jumlah_tidak_hadir_direksi: "",
      jumlah_hadir_sevp: "",
      jumlah_tidak_hadir_sevp: "",
      barcode_link: "",
      barcode_link_ori: "",
      rapat_direksi_id: this.$route.params.rd_id,

      //Modal TTD
      id_modal: 0,
      nama_modal: "",
      tipe_modal: "",
      drag: false,
      access_token_brigate: Cookies.get("access_token_brigate"),
    };
  },
  mounted() {
    this.getDivisi();
    this.getRapatDireksi();
  },
  methods: {
    getDivisi() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/divisi?status=" +
          self.status
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_divisi = response_data.list_data.data;
          }
        });
    },
    getRapatDireksi() {
      let self = this;
      self.loadingTable = true;

      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/administrasirapat/editabsensirapatdireksi?i=" +
          self.rapat_direksi_id
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            //console.log(response_data);
            var absensi_rapat = response_data.absensi_rapat;
            self.ar_tanggal = absensi_rapat.ar_tanggal;
            self.ar_jumlah = absensi_rapat.ar_jumlah;
            self.ar_lokasi = absensi_rapat.ar_lokasi;
            self.ar_lokasi_lainnya = absensi_rapat.ar_lokasi_lainnya;

            self.jumlah_hadir_sevp = response_data.jumlah_hadir_sevp;
            self.jumlah_tidak_hadir_sevp =
              response_data.jumlah_tidak_hadir_sevp;
            self.jumlah_hadir_direksi = response_data.jumlah_hadir_direksi;
            self.jumlah_tidak_hadir_direksi =
              response_data.jumlah_tidak_hadir_direksi;

            self.absensi_rapat_agenda = response_data.absensi_rapat_agenda;
            self.kehadiran_direksi = response_data.kehadiran_direksi;
            self.kehadiran_sevp = response_data.kehadiran_sevp;
            self.ttd_kehadiran_direksi = response_data.ttd_kehadiran_direksi;
            self.ttd_kehadiran_pemateri = response_data.ttd_kehadiran_pemateri;
            self.ttd_kehadiran_pendamping =
              response_data.ttd_kehadiran_pendamping;
            self.ttd_kehadiran_sevp = response_data.ttd_kehadiran_sevp;
            self.waktu_rapat = response_data.waktu_rapat;

            if (response_data.absensi_rapat_agenda) {
              var json_response_rapat_agenda =
                response_data.absensi_rapat_agenda;
              let clear_data_json_response_rapat_agenda = [];
              $.each(
                json_response_rapat_agenda,
                function (indexInArray, valueOfElement) {
                  clear_data_json_response_rapat_agenda.push({
                    agenda_rapat: valueOfElement.ara_nama,
                    jam_mulai: valueOfElement.ara_mulai,
                    jam_selesai: valueOfElement.ara_selesai,
                    div_pemateri: valueOfElement.divisi_pemateri_json,
                    div_pendamping: valueOfElement.divisi_pendamping_json,
                  });
                }
              );

              self.divisi_item = clear_data_json_response_rapat_agenda;
            }

            self.loadingTable = false;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Gagal Load Data!",
            });
          }

          Swal.close();
        });
    },
    lokasiRapatChange() {
      let self = this;
      if (self.ar_lokasi == "Lainnya") {
        self.showLokasiLainnya = true;
      } else {
        self.showLokasiLainnya = false;
      }
    },
    addagendarapat() {
      this.divisi_item.push({
        agenda_rapat: "",
        jam_mulai: "",
        jam_selesai: "",
        div_pemateri: [],
        div_pendamping: [],
      });
    },

    remove_divisi_item(index) {
      this.divisi_item.splice(index, 1);
    },

    StoreData() {
      let self = this;

      if (self.divisi_item.length == 0) {
        Swal.fire({
          icon: "warning",
          title: "Peringatan",
          text: "Agenda rapat tidak boleh kosong",
        });
        return false;
      }

      for (let i = 0; i < self.divisi_item.length; i++) {
        if (self.divisi_item[i].agenda_rapat == '' || self.divisi_item[i].jam_mulai == '' || self.divisi_item[i].jam_selesai == '' || self.divisi_item[i].div_pemateri.length == 0 || self.divisi_item[i].div_pendamping.length == 0) {
          Swal.fire({
            icon: 'error',
            title: 'Gagal!',
            text: "Pastikan formulir agenda rapat terisi lengkap",
          });
          return false;
        }
      }

      if (self.ar_jumlah > 100) {
        Swal.fire({
          icon: "warning",
          title: "Peringatan",
          text: "Jumlah kuorum melebihi 100",
        });
        return false;
      }

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("id_session_user", Cookies.get("session_id"));
      data.append("ar_id", self.rapat_direksi_id);
      data.append("ar_tipe_rapat", "rapat_direksi");
      data.append("ar_tanggal", self.ar_tanggal);
      data.append("ar_lokasi", self.ar_lokasi);
      data.append("ar_lokasi_lainnya", self.ar_lokasi_lainnya);
      data.append("ar_jumlah", self.ar_jumlah);
      data.append("agenda_rapat", JSON.stringify(self.divisi_item));

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/administrasirapat/updateabsensirapatdireksi",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data;
          if (response_data.code != 200) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          } else {
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman administrasi rapat direksi segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                // self.$router.push({ name: "vendor" });
                Swal.close();
                self.$router.push({ name: "rapat-direksi" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
    getDateIndo(input_date) {
      let arrbulan = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      let arrhari = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      var date = new Date(input_date);
      var hari = date.getDay();
      var tanggal = date.getDate();
      var bulan = date.getMonth();
      var tahun = date.getFullYear();
      return (
        arrhari[hari] + ", " + tanggal + " " + arrbulan[bulan] + " " + tahun
      );
    },
    buttonShowTtdModal(id, nama, tipe) {
      //console.log(id + nama + tipe);
      let self = this;
      self.showModalTtd = false;
      self.showFormTtd = false;
      // config untuk re render component
      self.$nextTick(() => {
        self.showModalTtd = true;
        self.showFormTtd = true;
        self.id_modal = id;
        self.nama_modal = nama;
        self.tipe_modal = tipe;
      });
    },
    refreshTable(type) {
      let self = this;
      if (type == "direksi") {
        self.showModalTtd = false;
        self.showFormTtd = false;
      }
      location.reload();
      // self.getDivisi();
      // self.getRapatDireksi();
    },
    hapusData(id) {
      var alert_text = "Apakah Anda yakin?";
      Swal.fire({
        title: "Warning ?",
        text: alert_text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          // let self = this;
          Swal.fire({
            title: '<i class="fas fa-spinner fa-spin"></i>',
            text: "Loading...",
            showConfirmButton: false,
          });
          var FormData = require("form-data");
          var data = new FormData();
          data.append("are_id", id);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/administrasirapat/tandatangan/deleteabsensidivisi",
            data: data,
          };
          axios(config)
            .then(function (response) {
              console.log(response);
              let timerInterval;
              Swal.fire({
                icon: "success",
                title: "Berhasil",
                text: "Anda akan diarahkan ke halaman detail rapat direksi segera",
                timer: 2000,
                timerProgressBar: true,
                showCancelButton: false,
                showConfirmButton: false,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              }).then((result) => {
                /* Read more about handling dismissals below */
                if (result.dismiss === Swal.DismissReason.timer) {
                  Swal.close();
                  location.reload();
                  // self.getDivisi();
                  // self.getRapatDireksi();
                  // self.loadingTable = false;
                }
              });
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        } else {
          Swal.close();
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="row text-end">
                <div class="col-md-8"></div>
                <div class="col-md-4">
                  <router-link
                    :to="{ name: 'rapat-direksi' }"
                    class="btn bg-warning btn-sm text-black"
                  >
                    <i class="fa fa-arrow-left"></i> Kembali
                  </router-link>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <b-tabs
                    justified
                    nav-class="nav-tabs-custom"
                    content-class="p-3 text-muted"
                  >
                    <b-tab active>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-home"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Edit Agenda Rapat</span>
                      </template>
                      <b-form
                        class="p-2"
                        @submit.prevent="StoreData"
                      >
                        <div class="p-2">
                          <!-- Tanggal Rapat -->
                          <b-form-group
                            id="fieldset-horizontal"
                            label-cols-sm="2"
                            label-cols-lg="2"
                            content-cols-sm
                            content-cols-lg="4"
                            label="Tanggal Rapat"
                            label-for="input-horizontal"
                          >
                            <b-form-input
                              id="input-horizontal"
                              type="date"
                              v-model="ar_tanggal"
                            ></b-form-input>
                          </b-form-group>

                          <b-form-group
                            id="fieldset-horizontal"
                            label-cols-sm="2"
                            label-cols-lg="2"
                            content-cols-sm
                            content-cols-lg="4"
                            label="Lokasi Rapat"
                            label-for="input-horizontal"
                            placeholder="Pilih Lokasi Rapat"
                          >
                            <v-select
                              id="input-horizontal"
                              :options="[
                                'Ruang Integrity / Gedung Kantor Pusat BRI',
                                'Work From Anywhere',
                                'Lainnya',
                              ]"
                              v-model="ar_lokasi"
                              @update:modelValue="lokasiRapatChange"
                            ></v-select>
                            <b-form-input
                              v-if="showLokasiLainnya"
                              id="input-horizontal"
                              type="text"
                              v-model="ar_lokasi_lainnya"
                              placeholder="Lokasi Lainnya"
                              class="mt-1"
                            ></b-form-input>
                          </b-form-group>
                          <!-- Jumlah Kuorum -->

                          <b-form-group
                            id="fieldset-horizontal"
                            label-cols-sm="2"
                            label-cols-lg="2"
                            content-cols-sm
                            content-cols-lg="4"
                            label="Jumlah Kuorum"
                            label-for="input-horizontal"
                          >
                            <b-form-input
                              id="input-horizontal"
                              type="number"
                              v-model="ar_jumlah"
                              min="0"
                              max="100"
                            ></b-form-input>
                          </b-form-group>

                          <!-- Agenda Rapat -->
                          <!-- <div>
                            <b-form-group
                              id="fieldset-horizontal"
                              label-cols-sm="2"
                              label-cols-lg="2"
                              content-cols-sm
                              content-cols-lg="4"
                              label="Agenda Rapat"
                              label-for="input-horizontal"
                            >
                            </b-form-group>
                          </div> -->
                        </div>
                        <div class="col-md-12">
                          <p style="font-size: 40px"><strong>Agenda Rapat</strong></p>
                          <p style="color: red">
                            <i>Silahkan klik tombol + untuk menambahkan data</i>
                          </p>
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr
                                style="background-color: #132d4a"
                                class="text-center text-white"
                              >
                                <th>Agenda Rapat</th>
                                <th>Jam Mulai</th>
                                <th>Jam Selesai</th>
                                <th>Divisi Pemateri</th>
                                <th>Divisi Pendamping</th>
                                <th>
                                  <button
                                    type="button"
                                    class="btn btn-success btn-sm"
                                    @click="addagendarapat"
                                  >
                                    <i class="fa fa-plus"></i>
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <draggable
                              v-model="divisi_item"
                              tag="tbody"
                              item-key="name"
                            >
                              <template #item="{ element }">
                                <tr>
                                  <td scope="row">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="element.agenda_rapat"
                                      placeholder="Masukan Nama Agenda"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="time"
                                      class="form-control"
                                      v-model="element.jam_mulai"
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="time"
                                      class="form-control"
                                      v-model="element.jam_selesai"
                                    />
                                  </td>
                                  <td>
                                    <v-select
                                      placeholder="Pilih Divisi Pemateri"
                                      v-model="element.div_pemateri"
                                      multiple="true"
                                      label="md_nama"
                                      :options="master_divisi"
                                    ></v-select>
                                  </td>
                                  <td>
                                    <v-select
                                      placeholder="Pilih Divisi Pendamping"
                                      v-model="element.div_pendamping"
                                      multiple="true"
                                      label="md_nama"
                                      :options="master_divisi"
                                    ></v-select>
                                  </td>
                                  <td>
                                    <button
                                      type="button"
                                      class="btn btn-danger btn-sm"
                                      @click="remove_divisi_item(index)"
                                    >
                                      <i class="fa fa-minus"></i>
                                    </button>
                                  </td>
                                </tr>
                              </template>
                            </draggable>
                          </table>
                        </div>
                        <div class="card-footer">
                          <div class="row">
                            <div class="col-md-6"></div>
                            <div class="col-md-6 text-end">
                              <button
                                type="submit"
                                class="btn btn-primary btn-sm"
                              >
                                <i class="fa fa-save"></i> SIMPAN
                              </button>
                            </div>
                          </div>
                        </div>
                      </b-form>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-user"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Edit Absensi Rapat Direksi</span>
                      </template>
                      <div class="row p-2">
                        <div class="col-md-12 mt-2">
                          <center>
                            <h4>Absensi Kehadiran</h4>
                          </center>
                        </div>
                        <div class="col-md-6">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <th>Hari, Tanggal</th>
                                <th style="width: 1px">:</th>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <th>Waktu</th>
                                <th style="width: 1">:</th>
                                <td>{{ waktu_rapat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-12 mt-3">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Nama</th>
                                <th
                                  colspan="2"
                                  style="width: 200px"
                                >
                                  Tanda Tangan
                                </th>
                                <th>Aksi</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loadingTable">
                                <td colspan="3">
                                  <i class="fas fa-spinner fa-spin"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="ttd_kehadiran_direksi == ''">
                                <td
                                  class="text-center"
                                  colspan="3"
                                >
                                  Data Tidak Tersedia
                                </td>
                              </tr>
                              <tr
                                v-else
                                v-for="(
                                  row_data, key_data
                                ) in ttd_kehadiran_direksi"
                                :key="key_data"
                              >
                                <td>{{ key_data + 1 }}</td>
                                <td>{{ row_data.nama }}</td>
                                <td>
                                  <div v-if="key_data % 2 == 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="key_data % 2 != 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td class="text-center">
                                  <div v-if="row_data.file_ttd">
                                    <button
                                      type="button"
                                      class="btn btn-danger"
                                      v-on:click="hapusData(row_data.are_id)"
                                    >
                                      <i class="fa fa-trash"></i>
                                      Hapus Absensi
                                    </button>
                                  </div>
                                  <div v-else>
                                    <button
                                      v-on:click="
                                        buttonShowTtdModal(
                                          row_data.id,
                                          row_data.nama,
                                          'Direksi'
                                        )
                                        "
                                      class="btn btn-success center"
                                    >
                                      <i class="fa fa-edit"></i> Tambahkan
                                      Absensi
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Edit Absensi SEVP</span>
                      </template>
                      <div class="row p-2">
                        <div class="col-md-12">
                          <center>
                            <h4>Absensi Kehadiran SEVP</h4>
                          </center>
                        </div>
                        <div class="col-md-6 mb-3">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <th>Hari, Tanggal</th>
                                <th style="width: 1px">:</th>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <th>Waktu</th>
                                <th style="width: 1">:</th>
                                <td>{{ waktu_rapat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-12">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Nama</th>
                                <th>Tanda Tangan</th>
                                <th>Aksi</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loadingTable">
                                <td colspan="3">
                                  <i class="fas fa-spinner fa-spin"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="ttd_kehadiran_sevp == ''">
                                <td
                                  class="text-center"
                                  colspan="3"
                                >
                                  Data Tidak Tersedia
                                </td>
                              </tr>
                              <tr
                                v-else
                                v-for="(
                                  row_data, key_data
                                ) in ttd_kehadiran_sevp"
                                :key="key_data"
                              >
                                <td>{{ key_data + 1 }}</td>
                                <td>{{ row_data.nama }}</td>
                                <td>
                                  <div v-if="key_data % 2 == 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="key_data % 2 != 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td class="text-center">
                                  <div v-if="row_data.file_ttd">
                                    <button
                                      type="button"
                                      class="btn btn-danger"
                                      v-on:click="hapusData(row_data.are_id)"
                                    >
                                      <i class="fa fa-trash"></i>
                                      Hapus Absensi
                                    </button>
                                  </div>
                                  <div v-else>
                                    <button
                                      v-on:click="
                                        buttonShowTtdModal(
                                          row_data.id,
                                          row_data.nama,
                                          'SEVP'
                                        )
                                        "
                                      class="btn btn-success center"
                                    >
                                      <i class="fa fa-edit"></i> Tambahkan
                                      Absensi
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Edit Absensi Pemateri</span>
                      </template>
                      <div class="row p-2">
                        <div class="col-md-12">
                          <center>
                            <h4>Absensi Kehadiran Divisi Pemateri pada</h4>
                          </center>
                        </div>
                        <div class="col-md-12">
                          <div class="text-end">
                            <button
                              v-on:click="
                                buttonShowTtdModal(null, null, 'Pemateri')
                                "
                              class="btn btn-success btn-sm center"
                            >
                              <i class="fa fa-plus"></i> Tambahkan Absensi
                            </button>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <th>Hari, Tanggal</th>
                                <th>:</th>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <th>Waktu</th>
                                <th>:</th>
                                <td>{{ waktu_rapat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-12 mt-3">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Nama Pejabat</th>
                                <th>Nama Divisi</th>
                                <th colspan="2">Tanda Tangan</th>
                                <th>Aksi</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loadingTable">
                                <td colspan="4">
                                  <i class="fas fa-spinner fa-spin"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="ttd_kehadiran_pemateri == ''">
                                <td
                                  class="text-center"
                                  colspan="4"
                                >
                                  Data Tidak Tersedia
                                </td>
                              </tr>
                              <tr
                                v-else
                                v-for="(
                                  row_data, key_data
                                ) in ttd_kehadiran_pemateri"
                                :key="key_data"
                              >
                                <td>{{ key_data + 1 }}</td>
                                <td>{{ row_data.nama }}</td>
                                <td>{{ row_data.divisi }}</td>
                                <td>
                                  <div v-if="key_data % 2 == 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="key_data % 2 != 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    v-on:click="hapusData(row_data.are_id)"
                                  >
                                    <i class="fa fa-trash"></i>
                                    Hapus Absensi
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-tab>

                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Edit Absensi Pendamping</span>
                      </template>
                      <div class="row p-2">
                        <div class="col-md-12">
                          <center>
                            <h4>Absensi Kehadiran Divisi Pendamping pada</h4>
                          </center>
                        </div>
                        <div class="col-md-12">
                          <div class="text-end">
                            <button
                              v-on:click="
                                buttonShowTtdModal(null, null, 'Pendamping')
                                "
                              class="btn btn-success btn-sm center"
                            >
                              <i class="fa fa-plus"></i> Tambahkan Absensi
                            </button>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <tbody>
                              <tr>
                                <td style="width: 200px">Hari, Tanggal</td>
                                <td>:</td>
                                <td>{{ getDateIndo(ar_tanggal) }}</td>
                              </tr>
                              <tr>
                                <td style="width: 200px">Waktu</td>
                                <td>:</td>
                                <td>{{ waktu_rapat }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-md-12">
                          <table class="table mb-0 table-bordered table-condensed table-hover table-striped">
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Nama Pejabat</th>
                                <th>Nama Divisi</th>
                                <th colspan="2">Tanda Tangan</th>
                                <th>Aksi</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-if="loadingTable">
                                <td colspan="4">
                                  <i class="fas fa-spinner fa-spin"></i>
                                  Loading...
                                </td>
                              </tr>
                              <tr v-else-if="ttd_kehadiran_pendamping == ''">
                                <td
                                  class="text-center"
                                  colspan="4"
                                >
                                  Data Tidak Tersedia
                                </td>
                              </tr>
                              <tr
                                v-else
                                v-for="(
                                  row_data, key_data
                                ) in ttd_kehadiran_pendamping"
                                :key="key_data"
                              >
                                <td>{{ key_data + 1 }}</td>
                                <td>{{ row_data.nama }}</td>
                                <td>{{ row_data.divisi }}</td>
                                <td>
                                  <div v-if="key_data % 2 == 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="key_data % 2 != 0">
                                    {{ key_data + 1 }}.
                                    <span v-if="row_data.file_ttd">
                                      <img
                                        alt=""
                                        style="
                                          width: 100px;
                                          height: 100px;
                                          border: 1px solid #7b7b7b;
                                          margin-bottom: 10px;
                                          object-fit: cover;
                                        "
                                        id="img-cv"
                                        v-bind:src="row_data.file_ttd"
                                      />
                                    </span>
                                    <span v-else> - </span>
                                  </div>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    v-on:click="hapusData(row_data.are_id)"
                                  >
                                    <i class="fa fa-trash"></i>
                                    Hapus Absensi
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <!-- modal tambah  -->
  <b-modal
    v-model="showModal"
    title="Tambah Absensi Direksi"
    title-class="text-black font-18"
    body-class="p-3"
    hide-footer
  >
  </b-modal>
  <b-modal
    v-model="showModalTtd"
    title="Tambah Absensi"
    title-class="text-black font-18"
    body-class="p-3"
    size="md"
    hide-footer
  >
    <form-ttd
      v-if="showFormTtd"
      :rapat_id="rapat_direksi_id"
      :id="id_modal"
      :nama="nama_modal"
      :tipe="tipe_modal"
      @refresh-table="refreshTable"
    ></form-ttd>
  </b-modal>
</template>
